<template>
  <div>
    <div v-if="isActiveFilter" class="d-flex align-center">
      <div v-for="(filterItem, index) in filters" :key="index">
        <v-btn
          :dark="selectedFilter === filterItem ? true : false"
          :outlined="selectedFilter !== filterItem ? true : false"
          x-small
          depressed
          class="mr-2 mb-3 mt-6"
          :style="{
            width: $vuetify.breakpoint.xs ? 'auto !important' : ' ',
            right: $vuetify.breakpoint.xs ? '4px !important' : ' '
          }"
          @click="selectedFilter = filterItem"
        >
          {{ filterItem }}
        </v-btn>
      </div>
    </div>
    <div class="pa-0">
      <v-data-table
        :headers="headers"
        :items="tableItemsValue"
        :items-per-page="100"
        :loading="loading"
        :hide-default-footer="true"
        sort-by="timestamp"
        sort-desc
        style="font-weight: bold"
      >
        <template #item.avatar="{ item }">
          <v-avatar size="30" color="grey lighten-2"><v-img :src="item.avatar" /></v-avatar>
        </template>
        <template #item.timestamp="{ item }">
          <span>{{ formatDate(item.timestamp) }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect } from '@vue/composition-api';
import { useUserState } from '@/store';
import moment from 'moment';

export default {
  props: {
    tableItems: {
      type: Array,
      default: () => {}
    },
    headers: {
      type: Array,
      default: () => {}
    },
    isActiveFilter: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const { user } = useUserState(['user']);
    const selectedFilter = ref('personal');
    const data = computed({
      get: () => props.tableItems,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const tableItemsValue = ref(data.value);
    watchEffect(() => {
      if (selectedFilter.value === 'team') {
        tableItemsValue.value = data.value;
      } else if (selectedFilter.value === 'personal') {
        tableItemsValue.value = data.value.filter(
          item => item?.user_id === user?.value?._id?.toString()
        );
      }
    });
    const formatDate = date => {
      return moment(date).fromNow();
    };
    return { formatDate, selectedFilter, tableItemsValue };
  }
};
</script>
