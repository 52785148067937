var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isActiveFilter)?_c('div',{staticClass:"d-flex align-center"},_vm._l((_vm.filters),function(filterItem,index){return _c('div',{key:index},[_c('v-btn',{staticClass:"mr-2 mb-3 mt-6",style:({
          width: _vm.$vuetify.breakpoint.xs ? 'auto !important' : ' ',
          right: _vm.$vuetify.breakpoint.xs ? '4px !important' : ' '
        }),attrs:{"dark":_vm.selectedFilter === filterItem ? true : false,"outlined":_vm.selectedFilter !== filterItem ? true : false,"x-small":"","depressed":""},on:{"click":function($event){_vm.selectedFilter = filterItem}}},[_vm._v(" "+_vm._s(filterItem)+" ")])],1)}),0):_vm._e(),_c('div',{staticClass:"pa-0"},[_c('v-data-table',{staticStyle:{"font-weight":"bold"},attrs:{"headers":_vm.headers,"items":_vm.tableItemsValue,"items-per-page":100,"loading":_vm.loading,"hide-default-footer":true,"sort-by":"timestamp","sort-desc":""},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
        var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30","color":"grey lighten-2"}},[_c('v-img',{attrs:{"src":item.avatar}})],1)]}},{key:"item.timestamp",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.timestamp)))])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }